import { defineComponent } from 'vue';
import moment from 'moment-timezone';
import Input from '../Input/Input.vue';
export default defineComponent({
    name: 'WatFocusTesterInfo',
    components: {
        Input: Input
    },
    computed: {
        disabled: function () {
            return this.form.bornDate == null || this.form.bornDate < 18 || this.form.bornDate > 99 || this.form.nickname.length < 3 || this.form.gender == null || !this.isValidEmail();
        }
    },
    data: function () {
        return {
            form: {
                province: {
                    id: 0
                },
                bornDate: null,
                nickname: '',
                gender: null,
                mail: ''
            }
        };
    },
    methods: {
        isValidEmail: function () {
            var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailRegex.test(this.form.mail);
        },
        save: function () {
            this.form.bornDate = moment().subtract(this.form.bornDate, 'years').toDate();
            this.$emit('completed', this.form);
        }
    }
});
